export const checkInStatus = {
    NAOINTEGRADO: 0,
    INTEGRADO: 1,
    ERROINTEGRACAO: 2
};

export const checkInStatusLista = [
    { status: 0, statusDescr: 'NÃO INTEGRADO' },
    { status: 1, statusDescr: 'INTEGRADO' },
    { status: 2, statusDescr: 'ERRO NA INTEGRAÇÃO' },
];