<template>
    <painel titulo="CheckIns" icone="pi pi-comments" :refreshFunction="atualizar" v-if="mostrarListagem">
        <Panel header="Filtros" :toggleable="true" class="filtro">
            <div class="formgrid grid p-fluid mt-2">
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data CheckIn (DE)</label>
                    <Calendar v-model="dataCheckInDe" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data CheckIn (ATÉ)</label>
                    <Calendar v-model="dataCheckInAte" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
            </div>
        </Panel>
        <msg-box :itens="mensagens"></msg-box>
        <tabela
            :data="checkIns"
            dataKey="checkInId"
            :globalFilterFields="['cpf', 'nome', 'email', 'statusDescr', 'tipoDescr']"
            :filtersProp="filtros"
            :filterCallBack="dadosFiltrados"
            stateKey="dt-checkins"
        >
            <template #botoes>
                <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mr-2" @click="clearFilter()" />
            </template>
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <btn-detalhar :checkIn="slotProps.data"></btn-detalhar>
                    </template>
                </Column>
                <Column field="dataCadastro" header="CheckIn em" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataCadastro, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
                <Column field="cpf" header="CPF" :sortable="true">
                    <template #body="slotProps">
                        <div class="white-space-nowrap">
                            {{ slotProps.data.cpf }}
                        </div>
                    </template>
                </Column>
                <Column field="nome" header="Nome" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.nome }}
                    </template>
                </Column>
                <Column field="email" header="Email" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.email }}
                    </template>
                </Column>
                <Column field="tipoDescr" header="Tipo" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.tipoDescr }}
                    </template>
                </Column>
                <Column field="statusDescr" header="Status" :sortable="true" filterField="status" :showFilterMatchModes="false">
                    <template #body="slotProps">
                        <status :status="slotProps.data.statusDescr"></status>
                    </template>
                    <template #filter="{ filterModel }">
                        <MultiSelect
                            v-model="filterModel.value"
                            :options="statusLista"
                            optionLabel="statusDescr"
                            optionValue="status"
                            placeholder="Qualquer"
                            class="p-column-filter"
                        >
                        </MultiSelect>
                    </template>
                </Column>
                <Column field="dataAtualizacao" header="Atualizado em" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import CheckInsServices from './services';
import BtnDetalhar from './BtnDetalhar';
import { checkInStatusLista } from './CheckInStatus';
import { FilterMatchMode } from 'primevue/api';

export default {
    components: {
        BtnDetalhar,
    },

    data() {
        return {
            checkIns: null,
            dataCheckInDe: null,
            dataCheckInAte: null,
            carregado: false,
            filtros: null,
            checkInsFiltrados: [],
            statusLista: [...checkInStatusLista],
            checkInsSelecionados: [],
            mensagens: [],
        };
    },

    methods: {
        obterCheckIns() {
            this.$store.dispatch('addRequest');
            let filtroDto = {
                dataCheckInDe: this.$moment(this.dataCheckInDe).format('YYYY-MM-DD'),
                dataCheckInAte: this.$moment(this.dataCheckInAte).format('YYYY-MM-DD'),
            };
            CheckInsServices.obterTodos(filtroDto).then((response) => {
                if (response?.success) {
                    this.checkIns = response.data;
                } else {
                    this.checkIns = null;
                }
                if (!this.carregado) {
                    this.carregado = true;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterCheckIns();
        },

        clearFilter() {
            this.filtros = null;
            this.iniciarFiltros();
        },

        iniciarFiltros() {
            this.filtros = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                status: { value: null, matchMode: FilterMatchMode.IN },
                codUnidadeAtendimento: { value: null, matchMode: FilterMatchMode.IN },
                codStatus: { value: null, matchMode: FilterMatchMode.IN },
            };
        },

        dadosFiltrados(filterData) {
            this.checkInsFiltrados = [...filterData.filteredValue];
        },

        atualizarMensagens(msgs) {
            this.mensagens = [...msgs];
        },
    },

    created() {
        this.dataCheckInAte = new Date();
        this.dataCheckInDe = new Date(this.$moment(this.dataCheckInAte).subtract(1, 'months').format('YYYY-MM-DD'));
        this.obterCheckIns();
    },

    mounted() {
        this.iniciarFiltros();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'AgenciaConceito_CheckIns_Listar';
        },

        yearRange() {
            let anoFinal = new Date().getFullYear();
            let anoInicial = anoFinal - 120;
            return `${anoInicial}:${anoFinal}`;
        },

        codStatusLista() {
            const result = [];
            const map = new Map();
            for (const item of this.atendimentos) {
                if (!map.has(item.codStatus)) {
                    map.set(item.codStatus, true); // set any value to Map
                    result.push(item.codStatus);
                }
            }
            return result.sort();
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'AgenciaConceito_CheckIns_Listar') {
                if (this.$store.getters.atualizar) {
                    this.obterCheckIns();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },

        dataCheckInDe() {
            if (this.carregado) {
                this.obterCheckIns();
            }
        },

        dataCheckInAte() {
            if (this.carregado) {
                this.obterCheckIns();
            }
        },
    },
};
</script>

<style>
.filtro .p-panel-header {
    background-color: rgb(212, 246, 255) !important;
}
</style>
